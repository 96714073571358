// Only global styles should go here!
@import "./spacing.scss";
@import "./mobileMenu.scss";
@import "./login.scss";
@import "./menu.scss";
@import "./productDetail.scss";

body,
html {
	margin: 0;
	font-family: "Barlow", sans-serif;
	line-height: "1.4";
	font-size: 1rem;
	scroll-behavior: smooth;
	letter-spacing: 0.015em;
	min-height: 100%;
}

// ========== fonts ==========
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
	font-family: "Saira", sans-serif;
	font-weight: 200;
}
@media (max-width: 991px) {
	.h1,
	h1 {
		font-size: 1.4rem;
	}
}

a {
	color: #168db5;
}

.p,
p,
div,
.div {
	line-height: 1.5;
	font-family: "Barlow", sans-serif;
	font-weight: inherit;
}

.bold {
	font-weight: 700;
}

.small {
	font-size: 80%;
}

.light {
	font-weight: "200";
}

.uppercase {
	text-transform: uppercase;
}

.link {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

.custom-arrows-intermediate {
	.slick-next:before {
		color: black !important;
		line-height: 0.3;
		margin-left: 6px;
		font-size: 30px;
		content: "›";
	}
	.slick-prev:before {
		color: black !important;
		line-height: 0.3;
		margin-left: 6px;
		font-size: 30px;
		content: "‹";
	}
}

/* Remove controls from Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
