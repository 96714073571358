.desktop-menu {
	* {
		box-sizing: border-box;
		letter-spacing: 0.015em;
		background: none;
	}

	.navbar {
		overflow: hidden;
		letter-spacing: 0.015em;
		margin-left: -32px;
		background: none;
	}

	.navbar a {
		float: left;
		font-size: 13px;
		text-align: center;
		padding: 7px 8px;
		text-decoration: none;
	}

	h4 {
		margin-bottom: 0;
		margin-top: 0;
	}

	.dropdown {
		float: left;
		overflow: hidden;
	}

	.dropdown .dropbtn {
		font-size: 16px;
		border: none;
		outline: none;
		padding: 5px 7px;
		font: inherit;
		margin: 0;
	}

	@media (max-width: 1250px) {
		.dropdown .dropbtn {
			font-size: 16px;
			border: none;
			outline: none;
			color: white;
			padding: 8px 4px;
			font: inherit;
			margin: 0;
		}
	}

	.navbar a:hover,
	.dropdown:hover .dropbtn {
		background-color: #7abfac;
		transition: 0.3s;
	}

	.dropdown-content {
		position: absolute;
		background-color: white;
		width: 120%;
		min-height: 300px;
		left: 0;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 9999999;
		visibility: hidden;
		transition: all 0s;
	}

	.dropdown:hover .dropdown-content {
		visibility: visible;
		transition-delay: 0.3s;
		-webkit-animation: fadein 0.5s;
		/* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 0.5s;
		/* Firefox < 16 */
		-ms-animation: fadein 0.5s;
		/* Internet Explorer */
		-o-animation: fadein 0.5s;
		/* Opera < 12.1 */
		animation: fadein 0.5s;
	}

	@keyframes fadein {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	/* Firefox < 16 */
	@-moz-keyframes fadein {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadein {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	/* Internet Explorer */
	@-ms-keyframes fadein {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	/* Opera < 12.1 */
	@-o-keyframes fadein {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	/* Create four equal columns that float next to each other */
	.column {
		margin-top: 20px;
		margin-bottom: 20px;
		float: left;
		max-width: 235px;
		padding-bottom: 10px;
		height: 100%;
		min-height: 150%;
		color: #274041;
	}

	.column a {
		float: none;
		color: #274041;
		padding: 0px;
		margin: 0px;
		text-decoration: none;
		display: block;
		letter-spacing: 0.015em;
	}

	.column a:hover {
		background-color: whitesmoke;
	}

	/* Responsive layout - makes the four columns stack on top of each other instead of next to each other */
	@media screen and (max-width: 600px) {
		.column {
			width: 100%;
			height: auto;
		}
	}
}
