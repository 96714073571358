.login {
	margin: auto;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
	width: 800px;
	text-align: center;

	.login-grid {
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: auto;
	}

	.form-div {
		// white window
		z-index: 1;
		width: 500px;
		height: 450px;
		padding: 0px;
		margin-top: -30px;
	}

	.form-div,
	.button-div {
		transition: 0.5s ease-out;
	}

	.button-div {
		// green window
		background-image: url("https://res.cloudinary.com/safehero/image/upload/v1571664490/Login/LoginBackground_sasfmc.png");
		background-color: #7abfac;
		object-fit: contain;
		height: 450px;
		width: 300px;
		padding: 20px;
		color: white;
	}

	form {
		display: flex;
		flex-direction: column;
	}
}
