.sidenav {
	width: 100%;
	overflow: hidden;
	.main-buttons {
		list-style-type: none;
		margin-top: 10px;
		padding: 0;
		color: #274041;
		li {
			font-family: "Open Sans", sans-serif;
		}
		& > li {
			padding: 7px 26px;
			box-sizing: border-box;
			border-bottom: 1px solid whitesmoke;
			border-left: 5px solid white;

			.icon {
				margin-left: -15px;
			}
			.category {
				padding-top: 1px;
			}
			.subcategoryHeadline {
				margin-top: 30px;
				font-weight: bold;
				background-color: whitesmoke;
			}

			&:hover,
			&:active,
			&:focus {
				.hidden {
					width: 85%;
					padding-bottom: 50px !important;
				}
				background-color: lighten(white, 5);
				cursor: pointer;
				transition: 0.3s;
				border-left: 5px solid #1b5c71;
			}
		}
	}
}

.hidden {
	width: 0;
	height: 70%;
	padding: 5px 0px;
	position: absolute;
	top: 140px;
	right: 0;
	overflow: hidden;
	list-style-type: none;
	background-color: lighten(white, 5);
	transition: 0.3s;
	border-left: 1px solid whitesmoke;
	box-shadow: -2px 0px 3px 0px rgba(0, 0, 0, 0.05);
	z-index: "2";
	li {
		padding: 5px 26px;
		font-family: "Open Sans", sans-serif;
		&:hover,
		&:active,
		&:focus {
			background-color: lighten(white, 9);
		}
	}
}
