// left
.ml-1 {
	margin-left: 0.25rem;
}

.ml-2 {
	margin-left: 0.5rem;
}

.ml-3 {
	margin-left: 0.75rem;
}

.ml-4 {
	margin-left: 1rem;
}

.ml-5 {
	margin-left: 1.25rem;
}

// top
.mt-1 {
	margin-top: 0.25rem;
}

.mt-2 {
	margin-top: 0.5rem;
}

.mt-3 {
	margin-top: 0.75rem;
}

.mt-4 {
	margin-top: 1rem;
}

.mt-5 {
	margin-top: 1.25rem;
}

// bottom
.mb-1 {
	margin-bottom: 0.25rem;
}

.mb-2 {
	margin-bottom: 0.5rem;
}

.mb-3 {
	margin-bottom: 0.75rem;
}

.mb-4 {
	margin-bottom: 1rem;
}

.mb-5 {
	margin-bottom: 1.25rem;
}

// right
.mr-1 {
	margin-right: 5px;
}

.mr-2 {
	margin-right: 10px;
}

.mr-3 {
	margin-right: 15px;
}

.mr-4 {
	margin-right: 20px;
}

.mr-5 {
	margin-right: 25px;
}

// top & bottom
.my-1 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.my-2 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.my-3 {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}

.my-4 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.my-5 {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

// left & right
.mx-1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

.mx-2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.mx-3 {
	margin-left: 0.75rem;
	margin-right: 0.75rem;
}

.mx-4 {
	margin-left: 1rem;
	margin-right: 1rem;
}

.mx-5 {
	margin-left: 1.25rem;
	margin-right: 1.25rem;
}
